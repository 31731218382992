@import '../../default';

.game-config{

    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    box-sizing: border-box;
    padding: 10px 20px;
    background-color: $color-candy-main-blue;
    color:#FFF;

    &::before{
        content: '';
        display: block;
        width: 100%;
        height: 70px;
    }

    header{
        width: 100%;
        height: 50px;
        background-color: $color-candy-shadow-blue;
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.6);
        box-sizing: border-box;
        padding: 5px;
        display: grid;
        grid-template-columns: min-content auto min-content;
        column-gap: 10px;
        align-items: center;
        color: #FFF;
        font-family: 'Roboto-Regular';
        font-weight: 100;
        z-index: 10;

        .close{
            color: #FFF;
            cursor: pointer;

            &:hover{
                filter: drop-shadow(0 0 5px #FFF);
            }
        }

    }

    .area-logo{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title{
        color: $color-candy-main-yellow;
        font-size: 32px;
    }    

}