@import './default';

body{
    margin: 0;
    padding: 0;
    font-family: 'Roboto-Regular';
    background-color: #F1F3F6;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}


#root{
    width: 100%;
    height: 100%;
    position: relative;
}

.game-admin{
    width: 100%;
    min-height: 100%;
    height: auto;
    position: absolute;
    background-color: $color-candy-main-blue;
    box-sizing: border-box;
    padding: 10px 20px;
    display: grid;
    grid-template-rows: 70px auto min-content;

    &::before{
        content: '';
        display: block;
        width: 100%;
        height: 70px;
        max-height: 70px;
    }

    > header{
        width: 100%;
        height: 50px;
        background-color: $color-candy-shadow-blue;
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.6);
        box-sizing: border-box;
        padding: 5px;
        display: grid;
        grid-template-columns: min-content 120px auto min-content;
        column-gap: 10px;
        align-items: center;
        color: #FFF;
        font-family: 'Roboto-Regular';
        font-weight: 100;
        z-index: 10;

        .logo{
            width: 80px;

            img{
                display: block;
                width: 100%;
            }
        }

        .data{
            display: grid;
            justify-content: center;
            grid-template-columns: 150px 100px 150px 100px;
        }

        .button{
            display: grid;
            justify-content: flex-end;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 5px;

            > * {
                cursor: pointer;
                transition: all ease 0.5s;

                &:hover{
                    transition: all ease 0.5s;
                }
            }

            .ranking{
                color: #ffff00;

                &:hover{
                    filter: drop-shadow(0 0 5px #ffff00);
                }
            }

            .help{
                color: $color-candy-main-rose;

                &:hover{
                    filter: drop-shadow(0 0 5px $color-candy-highlight-rose);
                }
            }
            
            .config{
                color: $color-candy-main-green;

                &:hover{
                    filter: drop-shadow(0 0 5px $color-candy-highlight-green);
                }
            }
            .close{
                color: $color-candy-main-yellow;

                &:hover{
                    filter: drop-shadow(0 0 5px $color-candy-highlight-yellow);
                }
            }

        }
    }

    .area-game{
        > .current-game{
            box-sizing: border-box;
            padding: 20px;
            background-color: $color-candy-highlight-blue;
            display: grid;
            grid-template-columns: 100px auto auto 130px;
            color: #FFF;
            border-radius: 5px;
            align-items: center;

            

            input[type="number"]{
                background-color: $color-candy-shadow-blue;
                color: #FFF;
                box-sizing: border-box;
                padding: 10px;
                margin: 0 10px;
                border-radius: 5px;
                border: 0;
                width: 80px;
            }
    
            .button-start{
                background-color: $color-candy-shadow-yellow;
                border: 0;
                box-sizing: border-box;
                padding: 10px 15px;
                color: #666;
                border-radius: 5px;
                font-size: 18px;
                cursor: pointer;
                transition: all ease 0.5s;
                box-shadow: 1.8px 1.8px 0 rgba(0, 0, 0, 0);
    
                &:hover{
                    transition: all ease 0.5s;
                    transform: scale(1.05);
                    box-shadow: 1.8px 1.8px 20px rgba(0, 0, 0, 0.3);
                }
    
            }
    
        }

        .list-bots{
            overflow: auto;
            height: 300px;
        }
    }

    

}