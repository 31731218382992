// Fonts
@font-face {
    font-family: 'Roboto-Regular';
    src: url('../font/Roboto-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NixieOne';
    src: url('../font/NixieOne-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'digital-7';
    src: url('../font/digital-7.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'square-deal';
    src: url('../font/square-deal.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

// Color Candy Land
$color-candy-highlight-rose: #EF7FA5;
$color-candy-main-rose: #EA5E89;
$color-candy-shadow-rose: #CE4986;
$color-candy-highlight-yellow: #FCE583;
$color-candy-main-yellow: #F9D953;
$color-candy-shadow-yellow: #F2C34D;
$color-candy-highlight-green: #6DE2BE;
$color-candy-main-green: #48CCA6;
$color-candy-shadow-green: #34AD88;
$color-candy-highlight-blue: #6C5BB5;
$color-candy-main-blue: #5A449A;
$color-candy-shadow-blue: #483987;

// Color Ball
$color-ball-b: #F00;
$color-ball-i: #039;
$color-ball-n: #F60;
$color-ball-g: #093;
$color-ball-o: #DEDB1F;
