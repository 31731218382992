@import '../../default';


$sunshine-colors : ("", "-rose", "-yellow", "-green", "-blue");

$background-ray-1: rgb(57, 15, 97);
$background-ray-2: rgb(44, 19, 68);

@function getColorSunshine($color, $number){

    $result: 0;

    @if $color == "-rose"{
        @if $number == 1 {
            $result: $color-candy-highlight-rose;
        }@else{
            $result:  $color-candy-shadow-rose;
        }
    }@else if $color == "-yellow"{
        @if $number == 1 {
            $result: $color-candy-highlight-yellow;
        }@else{
            $result:  $color-candy-shadow-yellow;
        }

    }@else if $color == "-green"{
        @if $number == 1 {
            $result: $color-candy-highlight-green;
        }@else{
            $result:  $color-candy-shadow-green;
        }
    }@else if $color == "-blue"{
        @if $number == 1 {
            $result: $color-candy-highlight-blue;
        }@else{
            $result:  $color-candy-shadow-blue;
        }
    }@else{
        @if $number == 1 {
            $result: rgb(57, 15, 97);
        }@else{
            $result:  rgb(44, 19, 68);
        }
    }
    
    @return $result;
}

@each $color in $sunshine-colors {


    .bg-sunshine#{$color}{
        
        
        $background-ray-1: getColorSunshine($color, 1);
        $background-ray-2: getColorSunshine($color, 2);
    

        position: relative;

        &:before{
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
    
            background-image: linear-gradient(180deg, transparent 50%, $background-ray-1 50%),
                              linear-gradient(195deg, transparent 50%, $background-ray-2 50%),
                              linear-gradient(210deg, transparent 50%, $background-ray-1 50%),
                              linear-gradient(225deg, transparent 50%, $background-ray-2 50%),
                              linear-gradient(240deg, transparent 50%, $background-ray-1 50%),
                              linear-gradient(255deg, transparent 50%, $background-ray-2 50%),
                              linear-gradient(270deg, transparent 50%, $background-ray-1 50%),
                              linear-gradient(285deg, transparent 50%, $background-ray-2 50%),
                              linear-gradient(300deg, transparent 50%, $background-ray-1 50%),
                              linear-gradient(315deg, transparent 50%, $background-ray-2 50%),
                              linear-gradient(330deg, transparent 50%, $background-ray-1 50%),
                              linear-gradient(345deg, transparent 50%, $background-ray-2 50%),
                              linear-gradient(360deg, transparent 50%, $background-ray-1 50%);
        }
    
        &:after{
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 50%;
            top: 50%;
            left: 0;
            z-index: 2;
            background-position: 0 100%;
            background-size: 100% 200%;
    
            background-image: linear-gradient(0deg, transparent 50%, $background-ray-1 50%),
                              linear-gradient(15deg, transparent 50%, $background-ray-2 50%),
                              linear-gradient(30deg, transparent 50%, $background-ray-1 50%),
                              linear-gradient(45deg, transparent 50%, $background-ray-2 50%),
                              linear-gradient(60deg, transparent 50%, $background-ray-1 50%),
                              linear-gradient(75deg, transparent 50%, $background-ray-2 50%),
                              linear-gradient(90deg, transparent 50%, $background-ray-1 50%),
                              linear-gradient(105deg, transparent 50%, $background-ray-2 50%),
                              linear-gradient(120deg, transparent 50%, $background-ray-1 50%),
                              linear-gradient(135deg, transparent 50%, $background-ray-2 50%),
                              linear-gradient(150deg, transparent 50%, $background-ray-1 50%),
                              linear-gradient(165deg, transparent 50%, $background-ray-2 50%),
                              linear-gradient(180deg, transparent 50%, $background-ray-1 50%);
        }

    }

    
}