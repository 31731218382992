@import '../../default';

.chat-game{
    background-color: $color-candy-main-rose;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: auto 200px;
    grid-template-rows: 200px auto;
    column-gap: 15px;
    row-gap: 15px;

    > * {
        border-radius: 5px;
    }

    .text{
        background-color: #FFF;
        grid-column: 1;
        grid-row: 1;
    }

    .input{
        background-color: #FFF;
        grid-column: 1;
        grid-row: 2;
        box-sizing: border-box;
        padding: 10px;
    }

    .users{
        grid-column: 2;
        grid-row: 1 / span 2;
        background-color: #FFF;
        box-sizing: border-box;
        padding: 10px;
    }
}