@import '../../default';

/*$color-b: rgba(255, 0, 0, 1);
$color-i: rgba(0, 51, 153, 1);
$color-n: rgba(255, 102, 0, 1);
$color-g: rgba(0, 153, 51, 1);
$color-o: rgba(222, 219, 31, 1);*/

$color-b: rgb(238, 41, 41);
$color-i: rgb(32, 29, 182);
$color-n: rgb(233, 88, 21);
$color-g: rgb(11, 136, 25);
$color-o: rgb(255, 225, 0);

$space-rows: 5px;


.ticket{
    width: 300px;
    height: 300px;
    display: inline-grid;
    grid-template-rows: 50px auto auto;
    row-gap: $space-rows;
    box-sizing: border-box;
    padding: 15px;
    background-color: $color-candy-main-blue;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    margin: 15px;
    overflow: hidden;
    font-family: 'Roboto-Regular';
    position: relative;
        
    &.rose{
        background-color: $color-candy-main-rose;
    }

    &.yellow{
        background-color: $color-candy-main-yellow;
    }

    &.green{
        background-color: $color-candy-main-green;
    }

    > header{

        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: $space-rows;
        row-gap: $space-rows;
        box-sizing: border-box;
        position: relative;
        z-index: 5;
        

        > .letter{
            
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            color: #000;
            
            font-family: 'Roboto-Regular';
            border-radius: 50%;
            width: 50px;
            height: 50px;
            box-sizing: border-box;
            padding: 10px;

            &::before{
                content: '';
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #FFF;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                top: 0;
                opacity: 0.3;
            }
        
            &::after{
                position: absolute;
                display: flex;
                content: attr(letter);
                align-items: center;
                justify-content: center;
                background-color: #FFF;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                font-size: 26px;
                font-weight: bold;
            }

            &:nth-child(1){
                background-color: $color-b;
                
            }

            &:nth-child(2){
                background-color: $color-i;
            }

            &:nth-child(3){
                background-color: $color-n;
            }

            &:nth-child(4){
                background-color: $color-g;
            }

            &:nth-child(5){
                background-color: $color-o;
            }
        }
    }

    > section{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: $space-rows;
        box-sizing: border-box;
        border-radius: 15px;
        position: relative;
        z-index: 5;


        .column{
            display: grid;
            grid-template-rows: repeat(5, 1fr);
            row-gap: $space-rows;
            column-gap: $space-rows;

            > .number {
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'digital-7';
                background-color: #FFF;
                font-size: 24px;
                color: #000;
                position: relative;
                user-select: none;

                &.star{
                    path{
                        fill:rgb(255, 230, 0);
                    }
                }

                > .check {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 90%;
                    max-width: 36px;
                    height: 90%;
                    max-height: 36px;
                    transform: translate(-50%, -50%);

                    path{
                        stroke-dasharray: 275.003, 275.003; 
                        stroke-dashoffset: 275.003; 
                        // transition: stroke-dashoffset 0.2s ease-in-out 0s;
                        stroke-width: 10px;
                        fill: transparent;
                        stroke: #F00;
                    }

                }

                &.active{

                    path{
                        stroke-dasharray: 275.003, 275.003; 
                        stroke-dashoffset: 0; 
                        // transition: stroke-dashoffset 0.8s ease-in-out 0s;
                    }
                }
            }
        }
    }

    footer{
        display: flex;
        align-items: flex-end;
    }
}