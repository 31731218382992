@import '../../default';
@import '../../effects/sunshine/';

.block-moda-win{

    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed !important;
    z-index: 999999;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Roboto-Regular';

    transform-origin: center center;

    animation: anim-win linear 0.5s;

    &.close{
        animation: anim-win-close linear 0.5s forwards;
    }

    @keyframes anim-win {
        0%{
            transform: scale(2);
            opacity: 0;
        }

        100%{
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes anim-win-close {
        100%{
            transform: scale(2);
            opacity: 0;
        }

        0%{
            transform: scale(1);
            opacity: 1;
        }
    }

    .modal{
        background-color: rgba(255, 255, 255, 1);
        box-shadow:0 0 5px #FFF,
                   0 0 25px #FFF, 
                   0 0 50px #FFF,
                   0 0 100px #FFF;
        box-sizing: border-box;
        padding: 20px;

        min-width: 300px;
        min-height: 300px;
        position: relative;
        z-index: 10;
        
        border-radius: 10px;

       .area-animation{
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: block;
            pointer-events: none;


            > span{
                position: absolute;
                border-radius: 25%;

                
                &:nth-child(1){
                    background-image: linear-gradient(90deg, transparent, $color-candy-highlight-rose);
                    top: 0;
                    left: 100%;
                    width: 100%;
                    height: 5px;
                    animation: anim-1 1s linear infinite;
                }

                &:nth-child(2){
                    background-image: linear-gradient(180deg, transparent, $color-candy-highlight-rose);
                    top: 100%;
                    right: 0;
                    width: 5px;
                    height: 100%;
                    animation: anim-2 1s linear infinite;
                    animation-delay: 0.25s;
                }

                &:nth-child(3){
                    background-image: linear-gradient(270deg, transparent, $color-candy-highlight-rose);
                    bottom: 0;
                    right: 100%;
                    width: 100%;
                    height: 5px;
                    animation: anim-3 1s linear infinite;
                    animation-delay: 0.50s;
                }

                &:nth-child(4){
                    background-image: linear-gradient(360deg, transparent, $color-candy-highlight-rose);
                    bottom: 0;
                    left: 0;
                    width: 5px;
                    height: 100%;
                    animation: anim-4 1s linear infinite;
                    animation-delay: 0.75s;
                }
            }

            @keyframes anim-1 {
                0%{
                    left: -100%;
                }

                50%, 100%{
                    left: 100%;
                }
            }

            @keyframes anim-2 {
                0%{
                    top: -100%;
                }

                50%, 100%{
                    top: 100%;
                }
            }

            @keyframes anim-3 {
                0%{
                    right: -100%;
                }

                50%, 100%{
                    right: 100%;
                }
            }

            @keyframes anim-4 {
                0%{
                    bottom: -100%;
                }

                50%, 100%{
                    bottom: 100%;
                }
            }
       }

        header{
            background-color: $color-candy-main-rose;
            width: 80%;
            height: 30px;
            position: absolute;
            top: -15px;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 5;

            border-radius: 5px 5px 30% 30%;

            &::before{
                content: '';
                display: block;
                width: 0px;
                height: 0px;
                position: absolute;
                z-index: 1;
                top: 0;
                left: -10px;
                border-width: 15px;
                border-color: $color-candy-shadow-rose $color-candy-shadow-rose $color-candy-shadow-rose transparent;
                border-style: solid;

                transform: translate(-50%, -5px);
            }

            &::after{
                content: '';
                display: block;
                width: 0px;
                height: 0px;
                position: absolute;
                z-index: 1;
                top: 0;
                right: -10px;
                border-width: 15px;
                border-color: $color-candy-shadow-rose transparent $color-candy-shadow-rose $color-candy-shadow-rose ;
                border-style: solid;

                transform: translate(50%, -5px);
            }
            
            .star{
                position: absolute;
                top: -25%;
                left: 50%;
                width: 72px;
                height: 72px;
                transform: translate(-50%, -50%);

                path{
                    fill: #ffff00;
                }
            }
        }

        > .letters-bingo{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            column-gap: 5px;
            row-gap: 5px;
            box-sizing: border-box;
            position: relative;
            z-index: 5;
            width: 100%;
            height: 50px;
            margin-top: 5px;


            $color-b: rgb(238, 41, 41);
            $color-i: rgb(32, 29, 182);
            $color-n: rgb(233, 88, 21);
            $color-g: rgb(11, 136, 25);
            $color-o: rgb(255, 225, 0);
            

            > .letter{
                
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                color: #000;
                
                font-family: 'Roboto-Regular';
                border-radius: 50%;
                width: 50px;
                height: 50px;
                box-sizing: border-box;
                padding: 10px;

                &:nth-child(even){
                    animation: anim-ball 1s linear infinite alternate;
                }

                &:nth-child(odd){
                    animation: anim-ball 1s linear infinite alternate-reverse;
                }


                @keyframes anim-ball {
                    0%{
                        transform: scale(1);
                    }

                    100%{
                        transform: scale(1.3);
                    }
                }

                &::before{
                    content: '';
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #FFF;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    top: 0;
                    opacity: 0.3;
                }
            
                &::after{
                    position: absolute;
                    display: flex;
                    content: attr(letter);
                    align-items: center;
                    justify-content: center;
                    background-color: #FFF;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    font-size: 26px;
                    font-weight: bold;
                }

                &:nth-child(1){
                    background-color: $color-b;
                    
                }

                &:nth-child(2){
                    background-color: $color-i;
                    top: 10px;
                }

                &:nth-child(3){
                    background-color: $color-n;
                    top: 20px;
                }

                &:nth-child(4){
                    background-color: $color-g;
                    top: 10px;
                }

                &:nth-child(5){
                    background-color: $color-o;
                }
            }
        }

        .result-bingo{
            position: relative;
            margin-top: 50px;
        }

        footer{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px;

            .button-close{
                background-color: $color-candy-shadow-blue;
                border: 0;
                box-sizing: border-box;
                padding: 10px 15px;
                color: #FFF;
                border-radius: 5px;
                font-size: 18px;
                cursor: pointer;
            }
        }

    }

}