@import '../../default';

.game-help{

    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    box-sizing: border-box;
    padding: 10px 20px;
    background-color: $color-candy-main-blue;
    color:#FFF;

    &::before{
        content: '';
        display: block;
        width: 100%;
        height: 70px;
    }

    header{
        width: 100%;
        height: 50px;
        background-color: $color-candy-shadow-blue;
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.6);
        box-sizing: border-box;
        padding: 5px;
        display: grid;
        grid-template-columns: min-content auto min-content;
        column-gap: 10px;
        align-items: center;
        color: #FFF;
        font-family: 'Roboto-Regular';
        font-weight: 100;
        z-index: 10;

        .close{
            color: #FFF;
            cursor: pointer;

            &:hover{
                filter: drop-shadow(0 0 5px #FFF);
            }
        }

    }

    .area-logo{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title{
        color: $color-candy-main-yellow;
        font-size: 32px;
        font-weight: bold;
    }

    .area-ticket-model{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .ticket-model{
        width: 85px;
        min-width: 85px;
        height: 85px;
        min-height: 85px;
        display: inline-grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(5, 1fr);
        column-gap: 1px;
        row-gap: 1px;
        margin: 5px 0;

        > div{
            background-color: #FFF;
        }

        $color-marker: $color-candy-main-rose;

        &:nth-child(1){
           > div{
                &:nth-child(1){
                    background-color: $color-marker;
                }
                &:nth-child(5n + 6){
                    background-color: $color-marker;
                }
            }
        }

        &:nth-child(2){
            > div{
                 &:nth-child(2){
                     background-color: $color-marker;
                 }
                 &:nth-child(5n + 7){
                     background-color: $color-marker;
                 }
             }
         }

         &:nth-child(3){
            > div{
                 &:nth-child(3){
                     background-color: $color-marker;
                 }
                 &:nth-child(5n + 8){
                     background-color: $color-marker;
                 }
             }
         }

         &:nth-child(4){
            > div{
                 &:nth-child(4){
                     background-color: $color-marker;
                 }
                 &:nth-child(5n + 9){
                     background-color: $color-marker;
                 }
             }
         }

         &:nth-child(5){
            > div{
                 &:nth-child(5){
                     background-color: $color-marker;
                 }
                 &:nth-child(5n + 5){
                     background-color: $color-marker;
                 }
             }
         }

         &:nth-child(6){
            > div{
                 &:nth-child(1){
                     background-color: $color-marker;
                 }

                 &:nth-child(-n+5){
                    background-color: $color-marker;
                }
             }
         }

         &:nth-child(7){
            > div{
                
                
                &:nth-child(n + 6):nth-child(-n + 10){
                     background-color: $color-marker;
                } 
             }
         }

         &:nth-child(8){
            > div{
                
                
                &:nth-child(n + 11):nth-child(-n + 15){
                     background-color: $color-marker;
                } 
             }
         }

         &:nth-child(9){
            > div{
                
                
                &:nth-child(n + 16):nth-child(-n + 20){
                     background-color: $color-marker;
                } 
             }
         }

         &:nth-child(10){
            > div{
                
                
                &:nth-child(n + 21):nth-child(-n + 25){
                     background-color: $color-marker;
                } 
             }
         }

         &:nth-child(11){
            > div{
                
                &:nth-child(6n + 1){
                     background-color: $color-marker;
                } 
             }
         }

         &:nth-child(12){
            > div{
                
                &:nth-child(4n + 1){
                     background-color: $color-marker;
                }
                
                &:first-child{
                    background-color: #FFF;
                }

                &:last-child{
                    background-color: #FFF;
                }
             }
         }

         &:nth-child(13){
            > div{
                
                &:nth-child(5){
                     background-color: $color-marker;
                }

                &:nth-child(21){
                    background-color: $color-marker;
               }
                
                &:first-child{
                    background-color: $color-marker;
                }

                &:last-child{
                    background-color: $color-marker;
                }
             }
         }

    }

}