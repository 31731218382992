@import '../../default';

[class*="ball-"]{
    border-radius: 50%;
    width: 64px;
    height: 64px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'digital-7';


    &::before{
        content: '';
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FFF;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        top: 0;
        opacity: 0.3;
    }

    &::after{
        position: absolute;
        display: flex;
        content: attr(number);
        align-items: center;
        justify-content: center;
        background-color: #FFF;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 26px;
        font-weight: bold;
    }
}

.ball-{
    &b{
        background-color: $color-ball-b;
    }

    &i{
        background-color: $color-ball-i;
    }

    &n{
        background-color: $color-ball-n;
    }

    &g{
        background-color: $color-ball-g;
    }

    &o{
        background-color: $color-ball-o;
    }

    &black{
        background-color: #000;
    }

}