@import '../../default';

.sortition-machine{

    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    margin: 10px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    font-family: 'digital-7';

    .icon{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: $color-candy-highlight-rose;
        top: 0;
        left: 0;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
    }

    .content{

        display: grid;
        grid-template-columns: repeat(15, 1fr);
        grid-template-rows: repeat(5, 1fr);
        width: calc(100% - 80px);
        column-gap: 3px;
        row-gap: 3px;

        > .number{
            position: relative;
            z-index: 10;
            background-color: rgba(255, 255, 255, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            padding: 5px;

            &.active{
                background-color: rgba(255, 255, 255, 1);
            }
        }
    }

    &::before{
        display: block;
        content: '';
        width: 150px;
        height: 110px;
        box-shadow: 0 0 0 99999px $color-candy-main-rose;
        border-radius: 50%;
        position: absolute;
        transform: translate(-50%, -50%) rotate(45deg);
        top: 0;
        left: 0;
    }


}